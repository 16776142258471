import * as React from 'react';
import {Header} from '@pluto-tv/lib-command-center-ts';
import {Provider} from 'react-redux';
import '@pluto-tv/assemble/dist/style.css';

import Layout from 'app/layout/Main';
import {isCommandCenter} from 'helpers/isCommandCenter';

import {store} from './store';
import './App.css';

export default (): JSX.Element => {
  if (isCommandCenter) {
    Header().init();
  }

  return (
    <Provider store={store}>
      <Layout />
    </Provider>
  );
};
