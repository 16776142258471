import * as React from 'react';
import {
  Cluster,
  ContentBoxes,
  ContentBox,
  Icon,
  Pagination,
  Table,
  TdLink,
  Template,
  TextInput,
  Status,
  secondsToHms,
} from '@pluto-tv/assemble';
import {debounce} from 'lodash-es';

import {useFindEpisodesByIdQuery} from 'features/clips/clipsApi';
import {IClipEpisode} from 'models/clips';

import {INestedClipId, INestedClipProps} from '../nestedPropsInterface';
import {useHistory} from 'react-router-dom';
import routes from 'routes/content.routes';
import {getPrefixedUrl} from 'routes';

const DEFAULT_NO_EPISODE_MSG = 'No episodes for this clip';

interface IClipEpisodesProps extends INestedClipProps, INestedClipId {}

export default ({clipId}: IClipEpisodesProps): JSX.Element => {
  const history = useHistory();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [hasEpisodes, setHasEpisodes] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(DEFAULT_NO_EPISODE_MSG);
  const [episodeTotals, setEpisodeTotals] = React.useState<number>(-1);
  const [episodeRes, setEpisodeRes] = React.useState<IClipEpisode[]>([]);
  const [episodeSearch, setEpisodeSearch] = React.useState<string>('');

  const {
    data: episodesData,
    isFetching,
    isError,
    error,
  } = useFindEpisodesByIdQuery(
    {
      id: clipId,
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      name: episodeSearch,
    },
    {refetchOnMountOrArgChange: true},
  );

  React.useEffect(() => {
    if (error && isError) {
      setEpisodeRes([]);

      if ((error as any).data.message === 'Episodes for requested clip ID not found') {
        if (episodeSearch?.trim().length > 0) {
          setErrMsg('No episodes match that search');
        } else if (hasEpisodes) {
          setErrMsg('No more episodes for this clip. Please navigate back.');
        } else {
          setErrMsg(DEFAULT_NO_EPISODE_MSG);
        }
        setEpisodeTotals(0);
      } else {
        setErrMsg('Error parsing episode results. Please try again later.');
      }
    }
  }, [isError, error, hasEpisodes, page, rowsPerPage, episodeSearch]);

  React.useEffect(() => {
    if (episodesData?.data && !isError && !isFetching) {
      // If it managed to load one page worth of data at least
      setHasEpisodes(true);

      setEpisodeRes(episodesData.data);

      if (episodesData.data.length < rowsPerPage) {
        setEpisodeTotals(page * rowsPerPage + episodesData.data.length);
      } else {
        setEpisodeTotals(episodesData.metadata.totalCount);
      }
    }
  }, [episodesData, isError, isFetching, page, rowsPerPage]);

  const navigateToEpisode = (row: IClipEpisode) => {
    history.push('/episodes/' + row.id);
  };

  const navigateToSeries = (row: IClipEpisode) => {
    history.push('/series/' + row.series.id);
  };

  const doSearch = debounce(
    (val: string) => {
      setEpisodeSearch(val);
      setPage(0);
    },
    500,
    {leading: false, trailing: true},
  );

  return (
    <ContentBoxes layout='cover'>
      <Template label='contentBoxesHeader'>
        <ContentBox>
          <TextInput placeholder='Search By Episode Name' iconLeft='search' value={episodeSearch} onChange={doSearch} />
        </ContentBox>
      </Template>
      <Template label='contentBoxesCover'>
        <ContentBox>
          <Template label='content'>
            <Table
              fixedHeader={true}
              id='episodes-table'
              loading={isFetching}
              emptyMsg={errMsg}
              wrapContent={true}
              fixedFirstCol={true}
              cols={[
                {
                  label: 'Episode Name',
                  colMinWidth: '12.5rem',
                  fixed: true,
                  transform: row => (
                    <TdLink
                      row={row}
                      title={row.name}
                      onClick={navigateToEpisode}
                      url={getPrefixedUrl(routes.paths.episodeEditDetailsPage.replace(':id', row.id))}
                    />
                  ),
                },
                {
                  label: 'Series Name',
                  colMinWidth: '12.5rem',
                  transform: row => (
                    <TdLink
                      row={row}
                      title={row.series.name}
                      url={getPrefixedUrl(`/series/${row.series.id}`)}
                      onClick={navigateToSeries}
                    />
                  ),
                },
                {
                  label: 'Duration',
                  colMinWidth: '6.25rem',
                  transform: row => secondsToHms(row.duration),
                },
                {
                  label: 'Ad Pods',
                  colMinWidth: '6.25rem',
                  transform: row => secondsToHms(row.adPodsDuration || 0),
                },
                {
                  label: 'Published',
                  colMinWidth: '6.875rem',
                  transform: row => (
                    <Status
                      label={row.published ? 'Published' : 'Unpublished'}
                      state={row.published ? 'success' : 'neutral'}
                    />
                  ),
                },
                {
                  label: 'Status',
                  colMinWidth: '10rem',
                  // transform: row => (row.status ? startCase(row.status) : '--'),
                  transform: row =>
                    row.status === 'in progress' || row.status === 'on air' ? (
                      <Icon
                        icon={row.status === 'in progress' ? 'inprogress' : 'onair'}
                        color={row.status === 'in progress' ? 'infoLight' : 'warning'}
                        pulse={row.status === 'in progress' ? true : false}
                        space='xxxsmall'
                      >
                        {row.status === 'in progress' ? 'In Progress' : 'On Air'}
                      </Icon>
                    ) : (
                      <Icon
                        space='xxxsmall'
                        icon={row.status === 'completed' ? 'check' : 'archive'}
                        color={row.status === 'completed' ? 'success' : 'neutral'}
                      >
                        {row.status === 'completed' ? 'Completed' : 'Archvied'}
                      </Icon>
                    ),
                },
                {
                  label: '# of S',
                  colMinWidth: '6.25rem',
                  field: 'season',
                },
                {
                  label: '# of E',
                  colMinWidth: '6.25rem',
                  field: 'number',
                },
                {
                  label: 'Next Air Date',
                  colMinWidth: '12.5rem',
                  transform: row => (row.nextTimeline ? new Date(row.nextTimeline.start).toLocaleString() : '--'),
                },
                {
                  label: 'Last Air Date',
                  colMinWidth: '12.5rem',
                  transform: row => (row.lastAired ? new Date(row.lastAired).toLocaleString() : '--'),
                },
                {
                  label: 'Active Region',
                  colMinWidth: '9.6875rem',
                  transform: row => (row.activeRegion ? row.activeRegion.toUpperCase() : '--'),
                },
                {
                  label: 'Actions',
                  colMinWidth: '6.25rem',
                  transform: row => (
                    <Icon
                      linkTarget='_blank'
                      icon='edit'
                      rel='opener'
                      href={getPrefixedUrl(routes.paths.episodeEditDetailsPage.replace(':id', row.id))}
                    />
                  ),
                },
              ]}
              rows={episodeRes}
            ></Table>
          </Template>
          <Template label='footer'>
            <Cluster justify='space-between'>
              <div></div>
              {episodeTotals > 0 && (
                <Pagination
                  total={episodeTotals}
                  disabled={isFetching}
                  perPage={rowsPerPage as 25 | 50 | 75 | 100}
                  currentPage={page}
                  onPageChange={page => {
                    setPage(page);
                    document.getElementById('episodes-table')?.parentElement?.scrollTo(0, 0);
                  }}
                  onPerPageChange={perPage => {
                    setRowsPerPage(perPage);
                    setPage(0);
                  }}
                />
              )}
            </Cluster>
          </Template>
        </ContentBox>
      </Template>
    </ContentBoxes>
  );
};
