import * as React from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {DateTime as luxon} from 'luxon';
import {
  Box,
  Button,
  Cluster,
  Cover,
  DateTime,
  Dialog,
  FormItem,
  Grid,
  Heading,
  Pagination,
  Paragraph,
  Select,
  Spinner,
  Status,
  Table,
  TdLink,
  Template,
  Textarea,
  TextInput,
  Toast,
  useValidateForm,
  IDateRange,
  trimModel,
  TIcons,
  disablePastDatesBehavior,
  ITableCol,
  Sidebar,
  Icon,
} from '@pluto-tv/assemble';

import campaignRoutes from 'routes/campaign.routes';
import NotAuthorized from 'components/notAuthorized';
import {useInsertMutation, useDeleteMutation, useLazyFindQuery} from 'features/campaigns/campaignsApi';

import {TableActions} from 'components/tableActions';

import {ICampaign, IListCampaignQuery} from 'models/campaigns';
import {campaignDetailsValidator, targetRegionValidator, publicationDateValidator} from '../validators';
import {useAppPermissions} from 'app/permissions';
import {useUserRegions} from 'helpers/useUserRegions';
import CampaignSearchBar from 'components/campaignList/campaignSearchBar';
import {IUserCampaignSearch, IUserEpisodeSearch} from 'models/users';
import {IListPayload, TSortDirection} from 'models/generic';
import CrudError from 'components/crudError';

const TABLE_COLUMN_NAME = {
  Name: 'name',
  'Display Name': 'displayName',
  Region: 'activeRegion',
  'Start Date': 'start',
  'End Date': 'end',
  Status: 'published',
} as const;

const defaultSearch: IUserCampaignSearch = {
  name: '',
  model: {},
  sortCol: 'Start Date' as keyof typeof TABLE_COLUMN_NAME,
  sortDir: 'dsc',
};
import {withThousandsSeparator} from 'utils/thousands-separator';
import {getPrefixedUrl} from 'routes';

const CampaignStatus = ({row}: {row: ICampaign}) => {
  if (row.published) {
    return <Status state='success' label='Published' />;
  }
  return <Status state='neutral' label='Unpublished' />;
};

interface LocationState {
  deletedCampaignName?: string;
}

export default (): JSX.Element => {
  const history = useHistory();
  const location = useLocation<LocationState>();
  const {ableTo, permissions} = useAppPermissions();

  if (!ableTo('CAMPAIGN_VIEW')) {
    return <NotAuthorized />;
  }

  React.useEffect(() => {
    if (location.state?.deletedCampaignName) {
      Toast.success('Success', `'${location.state.deletedCampaignName}' Campaign Deleted`);
      // Clear the state so the message doesn't show again
      history.replace({...location, state: {}});
    }
  }, [location, history]);

  const presetSortCol = defaultSearch.sortCol as keyof typeof TABLE_COLUMN_NAME;
  const presetSortDir = defaultSearch.sortDir;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const presetSearch: Partial<IListCampaignQuery> = {
    name: '',
  };

  const [sortCol, setSortCol] = React.useState<keyof typeof TABLE_COLUMN_NAME>(presetSortCol);
  const [sortDir, setSortDir] = React.useState<TSortDirection>(presetSortDir);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [searchExpanded, setSearchExpanded] = React.useState(false);
  const [createOpen, setCreateOpen] = React.useState(false);
  const [currentSearch, setCurrentSearch] = React.useState<IUserEpisodeSearch | undefined>({
    ...defaultSearch,
    sortCol: presetSortCol,
    sortDir: presetSortDir,
    ...(presetSearch && {
      model: {
        ...presetSearch,
      },
    }),
  });
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isError, setIsError] = React.useState<boolean>();

  const {
    activeRegions,
    isFetching: isFetchingRegions,
    isError: isErrorRegions,
    isSuccess: isSuccessRegions,
    territories,
    territoriesByRegion,
  } = useUserRegions();
  const [searchCampaigns, campaignsResponse] = useLazyFindQuery();
  const [insertCampaign] = useInsertMutation();
  const [removeCampaign] = useDeleteMutation();

  const [campaigns, setCampaigns] = React.useState<IListPayload<ICampaign>>();

  React.useEffect(() => {
    if (campaignsResponse?.isError) {
      if ((campaignsResponse?.error as any).status === 404) {
        setCampaigns({...campaignsResponse?.data, data: []} as IListPayload<ICampaign>);
      } else {
        setIsError(campaignsResponse?.isError);
      }
    } else {
      setCampaigns(campaignsResponse?.data);
    }

    if (campaignsResponse?.isFetching || campaignsResponse?.isLoading || campaignsResponse?.isUninitialized) {
      setIsLoading(true);
    } else {
      setTimeout(() => {
        setIsLoading(false);
      }, 100);
    }
  }, [campaignsResponse]);

  const {
    model: campaign,
    form: campaignForm,
    onBlur,
    onChange,
    setFields,
    state: formState,
    reset: resetForm,
  } = useValidateForm<ICampaign>([...campaignDetailsValidator, ...targetRegionValidator, ...publicationDateValidator]);

  const handleEdit = async (campaign: ICampaign, icon: TIcons) => {
    if (icon === 'edit') {
      history.push(campaignRoutes.paths.campaignEditPage.replace(':id', campaign._id));
    } else if (icon === 'delete') {
      try {
        await removeCampaign(campaign._id);
        Toast.success('Success', `'${campaign.name}' Campaign Deleted`);
      } catch (e) {
        Toast.error('Error', `Failed to remove ${campaign.name} campaign`);
      }
    }
  };

  const handleCancel = () => {
    setCreateOpen(false);
  };

  const openCreate = () => {
    resetForm();
    setCreateOpen(true);
  };

  const handleCreate = async (entity: Partial<ICampaign>) => {
    try {
      await insertCampaign(trimModel(entity, 'name', 'displayName', 'description')).unwrap();

      Toast.success('Success', 'Campaign Created');
      setCreateOpen(false);
      transformSearch();
    } catch (e) {
      const duplicateError = (e as any).data?.error;
      if (duplicateError === 'Conflict') {
        Toast.error('Validation Error', `Campaign with name '${entity.name}' already exists.`);
      }
    }
  };

  const changeSort = (columnName: keyof typeof TABLE_COLUMN_NAME) => {
    const newSortDir: TSortDirection = columnName === sortCol ? (sortDir === 'asc' ? 'dsc' : 'asc') : 'dsc';
    setSortCol(columnName);
    setSortDir(newSortDir);
    setCurrentSearch({
      ...currentSearch,
      sortCol: columnName,
      sortDir: newSortDir,
    } as IUserEpisodeSearch);
    setPage(0);
  };

  const handleSearch = React.useCallback((searchParams: IUserEpisodeSearch) => {
    setCurrentSearch(searchParams);
    setPage(0);
  }, []);

  const performSearch = (searchParams: IUserEpisodeSearch) => {
    searchCampaigns({
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      sort: (TABLE_COLUMN_NAME[searchParams.sortCol] || presetSortCol) + ':' + searchParams.sortDir,
      ...searchParams.model,
    });
  };

  const transformSearch = () => {
    if (currentSearch && !isFetchingRegions && !isErrorRegions) {
      performSearch({
        ...currentSearch,
        model: {
          ...currentSearch.model,
          activeRegions:
            (currentSearch.model.activeRegions || []).length === 0
              ? activeRegions.map(ar => ar.code)
              : currentSearch.model.activeRegions,
        },
      });
    }
  };

  const handleClear = React.useCallback(() => {
    const initialSearch: IUserEpisodeSearch = {
      ...defaultSearch,
      sortCol: presetSortCol,
      sortDir: presetSortDir,
      ...(presetSearch && {
        model: {
          ...presetSearch,
        },
      }),
    };

    setPage(0);
    setRowsPerPage(25);
    setSortCol(initialSearch.sortCol as keyof typeof TABLE_COLUMN_NAME);
    setSortDir(initialSearch.sortDir);
    setCurrentSearch(initialSearch);
  }, [presetSearch, presetSortCol, presetSortDir]);

  React.useEffect(() => {
    if (isSuccessRegions) {
      transformSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingRegions, isErrorRegions, currentSearch, activeRegions, page, rowsPerPage]);

  if (isError) {
    return <CrudError error='Error loading page data' />;
  }

  return (
    <Sidebar fullHeight={true}>
      <CampaignSearchBar
        sortDir={sortDir}
        sortCol={sortCol}
        isFetching={isLoading}
        isSearchExpanded={searchExpanded}
        setIsSearchExpanded={setSearchExpanded}
        showFavoriteSearch={true}
        search={currentSearch}
        onSearch={handleSearch}
        onClear={handleClear}
      />
      <Cover scrolling={true} gutter='large' coverTemplateHeight='100%' padding={{mobile: 'medium', wide: 'large'}}>
        <Template label='header'>
          <Cluster justify='space-between' align='center' space='medium'>
            <Cluster align='end' space='small'>
              <Heading level='h1' id='campaignTitle'>
                Campaigns
              </Heading>
              <Cluster space='xxsmall' align='center'>
                <Icon
                  icon='tune'
                  space='xxxsmall'
                  verticalAlign='bottom'
                  lineHeight='0px'
                  onClick={() => setSearchExpanded(!searchExpanded)}
                >
                  {withThousandsSeparator(campaigns?.metadata?.totalCount || 0)} Items
                </Icon>
              </Cluster>
            </Cluster>
            <Button type='primary' onClick={openCreate} id='addButton' permission={permissions.CAMPAIGN_CREATE}>
              + New Campaign
            </Button>
          </Cluster>
        </Template>
        <Template label='cover'>
          <Box
            background='pewter'
            borderTop={true}
            borderSize='0.125rem'
            borderColor='cavern'
            paddingTop={(campaigns?.data.length as number) > 0 ? 'xsmall' : 'medium'}
            paddingBottom='none'
            paddingX='large'
            fullHeight={true}
          >
            <Table
              id='campaignTable'
              loading={isLoading}
              emptyMsg='There are no campaigns currently available.'
              fixedHeader={true}
              onSort={colName => changeSort(colName as keyof typeof TABLE_COLUMN_NAME)}
              sortDir={sortDir}
              sortCol={sortCol}
              cols={[
                {
                  label: 'Name',
                  transform: row => (
                    <TdLink
                      row={row}
                      title={row.name}
                      url={getPrefixedUrl(campaignRoutes.paths.campaignEditPage.replace(':id', row._id))}
                      onClick={row => handleEdit(row, 'edit')}
                    />
                  ),
                },
                {
                  label: 'Display Name',
                  field: 'displayName',
                },
                {
                  label: 'Region',
                  sortable: true,
                  field: 'activeRegion',
                  colWidth: '4.375rem',
                },
                {
                  label: 'Start Date',
                  sortable: true,
                  colWidth: '11.25rem',
                  transform: row => new Date(row.start).toLocaleString(),
                },
                {
                  label: 'End Date',
                  sortable: true,
                  colWidth: '11.25rem',
                  transform: row => new Date(row.end).toLocaleString(),
                },
                {
                  label: 'Status',
                  sortable: true,
                  colWidth: '6.25rem',
                  transform: row => <CampaignStatus row={row} />,
                },
                ...(ableTo('CAMPAIGN_DELETE') || ableTo('CAMPAIGN_EDIT')
                  ? [
                      {
                        label: 'Actions',
                        colWidth: '6.25rem',
                        transform: row => (
                          <TableActions
                            row={row}
                            deleteOption={ableTo('CAMPAIGN_DELETE')}
                            displayField='name'
                            icons={ableTo('CAMPAIGN_EDIT') ? ['edit'] : []}
                            onClick={(row, icon) => handleEdit(row, icon)}
                          />
                        ),
                      } as ITableCol<ICampaign>,
                    ]
                  : []),
              ]}
              rows={campaigns?.data}
            >
              <Template label='loading'>
                <Cluster space='small' align='center'>
                  <Spinner />
                  <Paragraph>Loading Campaigns</Paragraph>
                </Cluster>
              </Template>
            </Table>
          </Box>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            {(campaigns?.data.length as number) > 0 && (
              <Pagination
                perPage={rowsPerPage as 25 | 50 | 75 | 100}
                currentPage={page}
                total={campaigns?.metadata?.totalCount || 0}
                onPageChange={page => setPage(page)}
                onPerPageChange={perPage => {
                  setRowsPerPage(perPage);
                  setPage(0);
                }}
              />
            )}
          </Cluster>
        </Template>
      </Cover>
      <Dialog isOpen={createOpen} width='32.8125rem' height='46rem' onClose={() => setCreateOpen(false)}>
        <Template label='header'>
          <Heading level='h3'>Create Campaign</Heading>
        </Template>
        <Template label='body'>
          <Grid gap='medium'>
            <FormItem {...campaignForm.name} onBlur={() => onBlur('name')}>
              <TextInput onChange={value => onChange('name', value)} id='title' placeholder='Campaign Name'></TextInput>
            </FormItem>
            <FormItem {...campaignForm.displayName} onBlur={() => onBlur('displayName')}>
              <TextInput
                onChange={value => onChange('displayName', value)}
                id='displayName'
                placeholder='Campaign Display Name'
              ></TextInput>
            </FormItem>
            <FormItem {...campaignForm.description} onBlur={() => onBlur('description')}>
              <Textarea
                onChange={value => onChange('description', value)}
                id='description'
                placeholder='Campaign Description'
                minHeight='6.25rem'
              />
            </FormItem>
            <FormItem {...campaignForm.activeRegion}>
              <Select
                placeholder='Target Region'
                id='targetRegion'
                value={{label: campaign?.activeRegion || '', value: campaign?.activeRegion}}
                options={(activeRegions || []).map(ar => ({label: `${ar.name} (${ar.code})`, value: ar.code}))}
                onChange={value => {
                  const activeRegion = value.value;
                  const regionFilter = territoriesByRegion[value.value]?.map(t => t.id) || [];

                  setFields({
                    activeRegion,
                    regionFilter,
                  });
                }}
                predicate='value'
              ></Select>
            </FormItem>
            <FormItem {...campaignForm.regionFilter}>
              <Select
                placeholder='Territories'
                id='territories'
                value={campaign.regionFilter?.map(d => ({label: d, value: d}))}
                multiselect={true}
                searchable={true}
                options={(territories || []).map(t => ({label: t.name, value: t.id}))}
                onChange={value =>
                  setFields({
                    regionFilter: (value || []).map(v => v.value),
                  })
                }
                predicate='value'
              ></Select>
            </FormItem>
            <FormItem {...campaignForm.start}>
              <DateTime
                placeholder='Publication Date'
                id='publicationDate'
                onBeforeDateRender={disablePastDatesBehavior}
                value={
                  campaign?.start
                    ? {start: new Date(campaign?.start || ''), end: new Date(campaign?.end || '')}
                    : undefined
                }
                range={true}
                time={true}
                appendToBody={true}
                onChange={value => {
                  const end = luxon
                    .fromJSDate((value as IDateRange).end as Date)
                    .endOf('minute')
                    .toJSDate();

                  setFields({
                    start: (value as IDateRange).start,
                    end,
                  });
                }}
              ></DateTime>
            </FormItem>
          </Grid>
        </Template>
        <Template label='footer'>
          <Cluster justify='space-between'>
            <div></div>
            <Cluster space='small'>
              <Button ghost={true} onClick={() => handleCancel()} id='cancelButton'>
                Cancel
              </Button>
              <Button
                type='primary'
                state={!formState.isValid ? 'disabled' : ''}
                onClick={() => handleCreate(campaign)}
                id='createButton'
              >
                Create
              </Button>
            </Cluster>
          </Cluster>
        </Template>
      </Dialog>
    </Sidebar>
  );
};
